import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Merheim: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Merheim,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Merheim"
          d="M819,560l-18,14l2,26l14,18l-20,24l2,14l-13,6l-14-20l-24-2v6h-4l-8-6l4-10l18-10l-6-14l-4-24l-0.5-1.8 c1.5-1.3,3.1-2.7,4.5-4.2l2-14l18,4l26-27L819,560z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 762.9025 620.0597)"
        >
          Merheim
        </text>
      </g>
    </>
  );
});
